const columns = [
  {
    field: 'desk',
    headerName: 'Desk',
    width: 110,
  },
  {
    field: 'commodity',
    headerName: 'Commodity',
    flex: 1,
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    type: 'number',
    width: 140,
  },
  {
    field: 'filledQuantity',
    headerName: 'Filled Quantity',
    type: 'number',
    width: 120,
  },
  {
    field: 'contractType',
    headerName: 'Contract Type',
    renderType: 'chip',
  },
  {
    field: 'taxCode',
    headerName: 'Tax Code',
    renderType: 'chip',
  },
  {
    field: 'isFilled',
    headerName: 'Is Filled',
    renderType: 'booleanIcon',
    align: 'center',
    width: 80,
  },
];

const rows = [
  {
    id: '8258bc3d-20da-51be-afc9-bb7bcfa58475',
    desk: 'D-2051',
    difficulty: 'Hard',
    category: 'Hard',
    recommendedPoints: 20,
    solveTime: 20,
    commodity: 'Frozen Concentrated Orange Juice',
    traderName: 'Herbert Perry',
    traderEmail: 'pu@jibabzo.am',
    quantity: 84908,
    filledQuantity: 0.8303928958402035,
    isFilled: true,
    status: 'Open',
    unitPrice: 20.70093124336563,
    unitPriceCurrency: 'TRY',
    subTotal: 1757674.670011689,
    feeRate: 0.2462190121179447,
    feeAmount: 432772.92087501247,
    incoTerm: 'DAP (Delivered at Place)',
    totalPrice: 1757674.9162307011,
    pnl: 23292242.409661412,
    maturityDate: '2021-06-12T09:26:32.473Z',
    tradeDate: '2021-03-22T02:19:44.987Z',
    brokerId: 'c4bd5c0e-f42b-52cc-94a6-23f82a5ad948',
    brokerName: 'Unisource Energy Corp',
    counterPartyName: 'Henry Schein Inc.',
    counterPartyCountry: {
      code: 'VU',
      label: 'Vanuatu',
      phone: '678',
    },
    counterPartyCurrency: 'ARS',
    counterPartyAddress: '517 Hawac Ridge',
    counterPartyCity: 'Norbontiv',
    taxCode: 'BR',
    contractType: { label: 'TM', variant: 'error' },
    rateType: 'Fixed',
    lastUpdated: '2021-03-21T04:14:48.646Z',
    dateCreated: '2020-10-27T14:34:40.746Z',
  },
  {
    id: 'c840296a-3a5c-5b7f-ae09-79a43ce62476',
    desk: 'D-1745',
    difficulty: 'Hard',
    category: 'Hard',
    recommendedPoints: 20,
    solveTime: 20,
    commodity: 'Sugar No.14',
    traderName: 'Laura Glover',
    traderEmail: 'veri@zam.gu',
    quantity: 17137,
    filledQuantity: 0.5065647429538426,
    isFilled: false,
    status: 'PartiallyFilled',
    unitPrice: 12.944144063163549,
    unitPriceCurrency: 'CAD',
    subTotal: 221823.79681043373,
    feeRate: 0.21546036121435466,
    feeAmount: 47794.23538671566,
    incoTerm: 'DAP (Delivered at Place)',
    totalPrice: 221824.01227079495,
    pnl: 24904967.565089464,
    maturityDate: '2021-06-13T17:02:29.708Z',
    tradeDate: '2021-03-21T04:31:52.613Z',
    brokerId: '0a79c728-27f1-578d-9e24-822eacc6efc7',
    brokerName: 'Sports Authority Inc',
    counterPartyName: 'Goodrich Corporation',
    counterPartyCountry: {
      code: 'LY',
      label: 'Libya',
      phone: '218',
    },
    counterPartyCurrency: 'ARS',
    counterPartyAddress: '446 Azpuw Lane',
    counterPartyCity: 'Giraeki',
    taxCode: 'BR',
    contractType: 'TM',
    rateType: 'Fixed',
    lastUpdated: '2021-03-21T11:31:28.646Z',
    dateCreated: '2020-11-25T19:53:00.269Z',
  },
  {
    id: '85689d9c-0e4d-57ac-9c16-396a24d9790d',
    desk: 'D-5787',
    difficulty: 'Hard',
    category: 'Hard',
    recommendedPoints: 20,
    solveTime: 20,
    commodity: 'Cocoa',
    traderName: 'Vincent Owen',
    traderEmail: 'fa@ro.ag',
    quantity: 53405,
    filledQuantity: 0.22808725774740193,
    isFilled: true,
    status: 'Filled',
    unitPrice: 10.869017175864428,
    unitPriceCurrency: 'BRZ',
    subTotal: 580459.8622770398,
    feeRate: 0.17093781714793296,
    feeAmount: 99222.54179962698,
    incoTerm: 'DPU (Delivered at Place Unloaded)',
    totalPrice: 580460.033214857,
    pnl: -1957540.0743633509,
    maturityDate: '2021-06-08T08:22:56.788Z',
    tradeDate: '2021-03-21T08:17:08.642Z',
    brokerId: 'ea5f8dd5-7815-5357-b74a-1b590428818c',
    brokerName: 'Sherwin-Williams Company',
    counterPartyName: 'PNC Financial Services Group Inc.',
    counterPartyCountry: {
      code: 'ER',
      label: 'Eritrea',
      phone: '291',
    },
    counterPartyCurrency: 'ARS',
    counterPartyAddress: '1091 Wukja Lane',
    counterPartyCity: 'Bezvivam',
    taxCode: 'BR',
    contractType: 'CR',
    rateType: 'Fixed',
    lastUpdated: '2021-03-21T23:44:30.802Z',
    dateCreated: '2021-01-01T23:25:33.905Z',
  },
  {
    id: 'a0b3fe52-c57e-57a0-8a7a-42b84e55671a',
    desk: 'D-6749',
    difficulty: 'Hard',
    category: 'Hard',
    recommendedPoints: 20,
    solveTime: 20,
    commodity: 'Rough Rice',
    traderName: 'Bernard McGuire',
    traderEmail: 'op@pafohig.mk',
    quantity: 48255,
    filledQuantity: 0.7857424101129417,
    isFilled: false,
    status: 'Filled',
    unitPrice: 94.98656413867138,
    unitPriceCurrency: 'NZD',
    subTotal: 4583576.652511587,
    feeRate: 0.38109650933183736,
    feeAmount: 1746785.062527074,
    incoTerm: 'EXW (Ex Works)',
    totalPrice: 4583577.033608097,
    pnl: 55482556.857168674,
    maturityDate: '2021-06-05T20:00:05.011Z',
    tradeDate: '2021-03-21T04:56:55.854Z',
    brokerId: 'e26a4b75-1db8-5235-b325-6480566aec6c',
    brokerName: 'La-Z-Boy Inc.',
    counterPartyName: 'Family Dollar Stores Inc.',
    counterPartyCountry: {
      code: 'NA',
      label: 'Namibia',
      phone: '264',
    },
    counterPartyCurrency: 'CHF',
    counterPartyAddress: '767 Jadza Court',
    counterPartyCity: 'Rulazuti',
    taxCode: 'BC45',
    contractType: 'TM',
    rateType: 'Floating',
    lastUpdated: '2021-03-21T10:21:13.333Z',
    dateCreated: '2020-07-06T04:12:24.291Z',
  },
  {
    id: '627fc9b1-6e2e-5b26-8631-c4f093e4553e',
    desk: 'D-1170',
    difficulty: 'Hard',
    category: 'Hard',
    recommendedPoints: 20,
    solveTime: 20,
    commodity: 'Sugar No.11',
    traderName: 'Mina Wilkins',
    traderEmail: 'sutiw@nande.re',
    quantity: 74605,
    filledQuantity: 0.94366329334495,
    isFilled: false,
    status: 'Open',
    unitPrice: 23.38927820441313,
    unitPriceCurrency: 'AUD',
    subTotal: 1744957.1004402416,
    feeRate: 0.21149743909481916,
    feeAmount: 369053.95807343227,
    incoTerm: 'DPU (Delivered at Place Unloaded)',
    totalPrice: 1744957.3119376807,
    pnl: -82883306.40643835,
    maturityDate: '2021-05-27T10:59:38.566Z',
    tradeDate: '2021-03-21T23:22:46.867Z',
    brokerId: 'ef4c362e-11e3-585e-a0bc-f18e93ea674b',
    brokerName: 'Dollar General Corporation',
    counterPartyName: 'W.R. Berkley Corporation',
    counterPartyCountry: {
      code: 'AI',
      label: 'Anguilla',
      phone: '1-264',
    },
    counterPartyCurrency: 'AUD',
    counterPartyAddress: '1743 Jiare Ridge',
    counterPartyCity: 'Zoumgez',
    taxCode: 'BC45',
    contractType: 'TM',
    rateType: 'Fixed',
    lastUpdated: '2021-03-21T06:13:20.769Z',
    dateCreated: '2021-03-13T08:24:03.130Z',
  },
  {
    id: '2e4d16ac-e4a8-5643-91ef-03e597cccfa9',
    desk: 'D-3791',
    difficulty: 'Hard',
    category: 'Hard',
    recommendedPoints: 20,
    solveTime: 20,
    commodity: 'Coffee C',
    traderName: 'Nannie Delgado',
    traderEmail: 'pocdumrif@pe.ye',
    quantity: 56505,
    filledQuantity: 0.2990177860366339,
    isFilled: false,
    status: 'Filled',
    unitPrice: 93.2846449916251,
    unitPriceCurrency: 'ARS',
    subTotal: 5271048.865251776,
    feeRate: 0.36399090895429254,
    feeAmount: 1918613.8676054862,
    incoTerm: 'DPU (Delivered at Place Unloaded)',
    totalPrice: 5271049.229242684,
    pnl: 77147413.6505276,
    maturityDate: '2021-08-02T07:12:46.292Z',
    tradeDate: '2021-03-21T16:46:59.448Z',
    brokerId: 'ef65876c-dc48-5337-a5be-05e6b5562d68',
    brokerName: 'Expeditors International of Washington Inc.',
    counterPartyName: 'Vishay Intertechnology Inc',
    counterPartyCountry: {
      code: 'KP',
      label: "Korea, Democratic People's Republic of",
      phone: '850',
    },
    counterPartyCurrency: 'EUR',
    counterPartyAddress: '987 Diksev River',
    counterPartyCity: 'Habauhi',
    taxCode: '1250L',
    contractType: 'FP',
    rateType: 'Fixed',
    lastUpdated: '2021-03-21T15:06:37.445Z',
    dateCreated: '2020-06-15T19:48:46.856Z',
  },
  {
    id: '3b8e71ca-d2e3-5816-b653-be0cb4ca5a3a',
    desk: 'D-5634',
    difficulty: 'Hard',
    category: 'Hard',
    recommendedPoints: 20,
    solveTime: 20,
    commodity: 'Cocoa',
    traderName: 'Elsie Parsons',
    traderEmail: 'buta@am.st',
    quantity: 95497,
    filledQuantity: 0.06045216080086285,
    isFilled: false,
    status: 'Filled',
    unitPrice: 77.03589050308801,
    unitPriceCurrency: 'BRZ',
    subTotal: 7356696.435373396,
    feeRate: 0.21792658381164076,
    feeAmount: 1603219.722300199,
    incoTerm: 'EXW (Ex Works)',
    totalPrice: 7356696.65329998,
    pnl: 82553092.76282787,
    maturityDate: '2021-09-19T00:55:19.154Z',
    tradeDate: '2021-03-21T13:41:07.582Z',
    brokerId: '1d2978d7-0e37-5d87-a477-a2bb172ddc45',
    brokerName: 'Interstate Bakeries Corporation',
    counterPartyName: 'Ryder System Inc',
    counterPartyCountry: {
      code: 'TZ',
      label: 'United Republic of Tanzania',
      phone: '255',
    },
    counterPartyCurrency: 'CHF',
    counterPartyAddress: '184 Gavven Point',
    counterPartyCity: 'Savadre',
    taxCode: '20G',
    contractType: 'FP',
    rateType: 'Floating',
    lastUpdated: '2021-03-21T14:54:09.991Z',
    dateCreated: '2020-07-18T06:02:50.722Z',
  },
  {
    id: '37983db4-7c24-5443-bda0-17c66deef594',
    desk: 'D-5452',
    difficulty: 'Hard',
    category: 'Hard',
    recommendedPoints: 20,
    solveTime: 20,
    commodity: 'Rapeseed',
    traderName: 'Amanda Boone',
    traderEmail: 'duipwic@wuc.sv',
    quantity: 55566,
    filledQuantity: 0.16877227081308713,
    isFilled: false,
    status: 'Open',
    unitPrice: 64.23068571230397,
    unitPriceCurrency: 'EUR',
    subTotal: 3569042.282289882,
    feeRate: 0.15145812686532736,
    feeAmount: 540560.4587787784,
    incoTerm: 'FAS (Free Alongside Ship)',
    totalPrice: 3569042.433748009,
    pnl: 72975128.7959516,
    maturityDate: '2021-11-11T17:49:17.441Z',
    tradeDate: '2021-03-21T16:59:04.457Z',
    brokerId: '45a01e38-2868-5617-bba3-369496f099e5',
    brokerName: 'SCI Systems Onc.',
    counterPartyName: 'Pulte Homes Inc',
    counterPartyCountry: {
      code: 'MX',
      label: 'Mexico',
      phone: '52',
    },
    counterPartyCurrency: 'MXN',
    counterPartyAddress: '1629 Daaw Parkway',
    counterPartyCity: 'Bavcawku',
    taxCode: '1250L',
    contractType: 'TM',
    rateType: 'Floating',
    lastUpdated: '2021-03-21T06:27:05.537Z',
    dateCreated: '2020-06-25T13:25:17.740Z',
  },
  {
    id: 'a27d9524-80be-5d71-9922-cd47762ff7fa',
    desk: 'D-8748',
    difficulty: 'Hard',
    category: 'Hard',
    recommendedPoints: 20,
    solveTime: 20,
    commodity: 'Sugar No.14',
    traderName: 'Allie Hodges',
    traderEmail: 'padciw@dimaniko.km',
    quantity: 58942,
    filledQuantity: 0.6972786807369957,
    isFilled: false,
    status: 'Open',
    unitPrice: 54.63024697848596,
    unitPriceCurrency: 'JPY',
    subTotal: 3220016.0174059193,
    feeRate: 0.35681026619859046,
    feeAmount: 1148934.7723343312,
    incoTerm: 'DDP (Delivered Duty Paid)',
    totalPrice: 3220016.3742161854,
    pnl: 64662338.70014548,
    maturityDate: '2021-05-19T10:18:41.284Z',
    tradeDate: '2021-03-21T23:13:01.070Z',
    brokerId: 'cbec7fef-224e-52ff-9476-47b108f195e1',
    brokerName: 'AMC Entertainment Inc.',
    counterPartyName: 'Kerr-McGee Corporation',
    counterPartyCountry: {
      code: 'MQ',
      label: 'Martinique',
      phone: '596',
    },
    counterPartyCurrency: 'THB',
    counterPartyAddress: '1028 Jarti Pass',
    counterPartyCity: 'Inizovug',
    taxCode: '1250L',
    contractType: 'TM',
    rateType: 'Floating',
    lastUpdated: '2021-03-21T16:18:49.976Z',
    dateCreated: '2020-04-07T02:45:56.484Z',
  },
  {
    id: 'fba6664f-f15e-5eab-8823-74b86166d751',
    desk: 'D-2703',
    difficulty: 'Hard',
    category: 'Hard',
    recommendedPoints: 20,
    solveTime: 20,
    commodity: 'Sugar No.14',
    traderName: 'Cory Smith',
    traderEmail: 'zifsid@jooc.ge',
    quantity: 29617,
    filledQuantity: 0.43248809805179456,
    isFilled: false,
    status: 'Rejected',
    unitPrice: 44.32662709639408,
    unitPriceCurrency: 'BRZ',
    subTotal: 1312821.7147139034,
    feeRate: 0.13957420594524594,
    feeAmount: 183236.04837886928,
    incoTerm: 'CPT (Carriage Paid To)',
    totalPrice: 1312821.8542881093,
    pnl: -5721515.929326415,
    maturityDate: '2021-07-31T02:23:28.549Z',
    tradeDate: '2021-03-21T23:30:04.025Z',
    brokerId: '2f997193-2304-5fc4-8619-ddbc371eb566',
    brokerName: 'Graybar Electric Company Inc.',
    counterPartyName: 'Martin Marietta Materials Inc.',
    counterPartyCountry: {
      code: 'JP',
      label: 'Japan',
      phone: '81',
      suggested: true,
    },
    counterPartyCurrency: 'THB',
    counterPartyAddress: '365 Reseg Pass',
    counterPartyCity: 'Zovzagjel',
    taxCode: 'IGN179',
    contractType: 'FP',
    rateType: 'Floating',
    lastUpdated: '2021-03-22T00:57:03.728Z',
    dateCreated: '2020-10-06T22:27:05.250Z',
  },
  {
    id: 'd5a16af5-511f-5fd8-b510-5c866a926e1b',
    desk: 'D-3729',
    difficulty: 'Hard',
    category: 'Hard',
    recommendedPoints: 20,
    solveTime: 20,
    commodity: 'Frozen Concentrated Orange Juice',
    traderName: 'Bruce Baldwin',
    traderEmail: 'gonseul@cukujrew.ls',
    quantity: 40556,
    filledQuantity: 0.8107061840418187,
    isFilled: false,
    status: 'PartiallyFilled',
    unitPrice: 40.55473470245488,
    unitPriceCurrency: 'NZD',
    subTotal: 1644737.82059276,
    feeRate: 0.1341853245627135,
    feeAmount: 220699.67827680957,
    incoTerm: 'FCA (Free Carrier)',
    totalPrice: 1644737.9547780848,
    pnl: 35832824.13892448,
    maturityDate: '2021-09-22T13:26:51.290Z',
    tradeDate: '2021-03-21T05:35:56.756Z',
    brokerId: 'c887413e-0c4c-559c-be2e-f709cf4eccdc',
    brokerName: 'Consolidated Edison Inc.',
    counterPartyName: 'AutoNation, Inc.',
    counterPartyCountry: {
      code: 'MS',
      label: 'Montserrat',
      phone: '1-664',
    },
    counterPartyCurrency: 'MXN',
    counterPartyAddress: '1607 Dako Key',
    counterPartyCity: 'Morbiana',
    taxCode: 'BC45',
    contractType: 'TM',
    rateType: 'Floating',
    lastUpdated: '2021-03-21T06:41:48.447Z',
    dateCreated: '2020-10-18T15:53:23.221Z',
  },
];

export { columns, rows };
