import React from 'react';
import { Box } from '@material-ui/core';
import { FdTab } from '@fifthdomain/fe-shared';

const Tabs = () => {
  const DataTable = () => <></>;

  return (
    <Box mt={10} ml={30}>
      <FdTab
        label={[
          {
            label: 'All Challenges',
            path: '/challenges/all',
            index: 0,
            data: () => DataTable(),
          },
          {
            label: 'Drafts',
            path: '/challenges/drafts',
            index: 1,
            data: () => DataTable(),
          },
          {
            label: 'Blocked',
            path: '/challenges/blocked',
            index: 2,
            badge: 3,
            data: () => DataTable(),
          },
          {
            label: 'Submitted',
            path: '/challenges/submitted',
            index: 3,
            disabled: true,
            data: () => DataTable(),
          },
          {
            label: 'Approved',
            path: '/challenges/approved',
            index: 4,
            data: () => DataTable(),
          },
          {
            label: 'Archived',
            path: '/challenges/archived',
            index: 5,
            data: () => DataTable(),
          },
        ]}
      />
    </Box>
  );
};

export default Tabs;
