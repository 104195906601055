import React from 'react';
import { FdTypography } from '@fifthdomain/fe-shared';
import { Box, Divider, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
  },
});

function Typography() {
  const classes = useStyles();
  return (
    <Box p={10} className={classes.root}>
      <FdTypography variant="h1"> h1.Primary</FdTypography>
      <FdTypography variant="h2">h2 </FdTypography>
      <FdTypography variant="h3">h3 </FdTypography>
      <FdTypography variant="h4">h4 </FdTypography>
      <FdTypography variant="body1">body1 </FdTypography>
      <FdTypography variant="body2">body2 </FdTypography>
      <FdTypography variant="subtitle1">subtitle1 </FdTypography>
      <FdTypography variant="subtitle2">subtitle2 </FdTypography>
      <FdTypography variant="button_lg" display="block">
        button_lg{' '}
      </FdTypography>
      <FdTypography variant="button_med" display="block">
        button_med{' '}
      </FdTypography>
      <FdTypography variant="button_sm" display="block">
        button_sm{' '}
      </FdTypography>
      <FdTypography variant="captiontext1" display="block">
        captiontext1{' '}
      </FdTypography>
      <FdTypography variant="captiontext2" display="block">
        captiontext2{' '}
      </FdTypography>
      <Divider />
      <FdTypography color="secondary" variant="h1">
        {' '}
        h1.Secondary
      </FdTypography>
      <FdTypography color="secondary" variant="h2">
        h2{' '}
      </FdTypography>
      <FdTypography color="secondary" variant="h3">
        h3{' '}
      </FdTypography>
      <FdTypography color="secondary" variant="h4">
        h4{' '}
      </FdTypography>
      <FdTypography color="secondary" variant="body1">
        body1{' '}
      </FdTypography>
      <FdTypography color="secondary" variant="body2">
        body2{' '}
      </FdTypography>
      <FdTypography color="secondary" variant="subtitle1">
        subtitle1{' '}
      </FdTypography>
      <FdTypography color="secondary" variant="subtitle2">
        subtitle2{' '}
      </FdTypography>
      <FdTypography color="secondary" variant="button_lg" display="block">
        button_lg{' '}
      </FdTypography>
      <FdTypography color="secondary" variant="button_med" display="block">
        button_med{' '}
      </FdTypography>
      <FdTypography color="secondary" variant="button_sm" display="block">
        button_sm{' '}
      </FdTypography>
      <FdTypography color="secondary" variant="captiontext1" display="block">
        captiontext1{' '}
      </FdTypography>
      <FdTypography color="secondary" variant="captiontext2" display="block">
        captiontext2{' '}
      </FdTypography>
    </Box>
  );
}

export default Typography;
