import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { FdAlert, FdTypography } from '@fifthdomain/fe-shared';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
  },
});

const Alert = () => {
  const classes = useStyles();

  return (
    <Box m={10} width="70%">
      <Box className={classes.container} mb={5}>
        <FdTypography variant="h2">Multiple Line Alerts</FdTypography>
        <FdAlert
          alertTitle="Competency is required-default"
          variant="default"
          message="Please choose a competency"
        />
        <FdAlert
          alertTitle="Competency is required-Info"
          variant="info"
          message="Please choose a competency"
        />
        <FdAlert
          alertTitle="Competency is required-Success"
          variant="success"
          message="Please choose a competency"
        />
        <FdAlert
          alertTitle="Competency is required-Warning"
          variant="warning"
          message="Please choose a competency"
        />
        <FdAlert
          alertTitle="Competency is required-Error"
          variant="error"
          message="Please choose a competency"
        />
      </Box>
      <Box className={classes.container}>
        <FdTypography variant="h2">Single Line Alerts</FdTypography>
        <FdAlert message="Competency is required-default" variant="default" />
        <FdAlert message="Competency is required-Info" variant="info" />
        <FdAlert message="Competency is required-Success" variant="success" />
        <FdAlert message="Competency is required-Warning" variant="warning" />
        <FdAlert message="Competency is required-Error" variant="error" />
      </Box>
    </Box>
  );
};

export default Alert;
