import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { FdCheckbox, FdTypography, FdExternalLink } from '@fifthdomain/fe-shared';

const singleOptionValue = 'agreedTOS';
const singleOptionLabel = (
  <FdTypography variant="body1">
    {'I agree to FifthDomain’s '}
    <FdExternalLink href="/" underline="always">
      Terms of Service
    </FdExternalLink>
    ,
    {' '}
    <FdExternalLink href="/" underline="always">
      Intellectual Property Policy
    </FdExternalLink>
    {' and '}
    <FdExternalLink href="/" underline="always">
      Standards of Use Policy.
    </FdExternalLink>
  </FdTypography>
);
const singleOptionLabel2 = (
  <FdTypography variant="body1">
    {'I agree to FifthDomain’s '}
    <FdExternalLink href="/" underline="always">
      Privacy Policy.
    </FdExternalLink>
  </FdTypography>
);

const multiOptions = {
  test1: singleOptionLabel,
  test2: singleOptionLabel2,
  test3: singleOptionLabel,
  test4: 'Cake',
  test5: 'Pudding',
};

const Checkbox = () => (
  <>
    {/* Checkbox individual */}
    <Box mt={10} ml={30}>
      <FdCheckbox
        value={singleOptionValue}
        label={singleOptionLabel}
        checked
        helperText="Help Text"
        formLegend="Single Selection"
      />
    </Box>

    {/* Checkbox multiple */}
    <Box mt={10} ml={30}>
      <FdCheckbox
        name="multipleOption"
        helperText="Help Text"
        options={multiOptions}
        multiple
        formLegend="Multiple Options"
        disabledList={['test3', 'test5']}
        defaultSelected={['test2', 'test1']}
      />
    </Box>

    {/* Checkbox error */}
    <Box mt={10} ml={30}>
      <FdCheckbox
        name="multipleOptionError"
        helperText="Help Text"
        options={multiOptions}
        multiple
        formLegend="Multiple Options error"
        error
      />
    </Box>

    {/* Checkbox disabled */}
    <Box mt={10} ml={30}>
      <FdCheckbox
        name="multipleOptionDisabled"
        helperText="Help Text"
        options={multiOptions}
        multiple
        formLegend="Multiple Options disabled"
        disabled
      />
    </Box>

  </>
);

export default Checkbox;
