import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { FdMarkdownEditor } from '@fifthdomain/fe-shared';

const MarkdownEditor = () => {
  const [value, setValue] = useState('Hello World!');
  return (
    <Box mt={10} ml={5} mr={5}>
      <FdMarkdownEditor
        id="rulesMarkdown"
        name="rulesMarkdown"
        markdown={value}
        setMarkdown={setValue}
        label="Markdown editor"
        required
      />
    </Box>
  );
};

export default MarkdownEditor;
