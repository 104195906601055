import React from 'react';
import { Box } from '@material-ui/core';
import { FdSelect, FdAutocomplete } from '@fifthdomain/fe-shared';

const autoCompleteOptions = [
  { lab: 'The Shawshank Redemption', val: '2199422' },
  { lab: 'The Godfather', val: '1972' },
  { lab: 'The Godfather: Part II', val: '1974' },
  { lab: 'The Dark Knight', val: '2008' },
  { lab: '12 Angry Men', val: '1957' },
  { lab: "Schindler's List", val: '1993' },
  { lab: 'Pulp Fiction', val: '19942' },
  { lab: 'The Lord of the Rings: The Return of the King', val: '2003' },
  { lab: 'The Good, the Bad and the Ugly', val: '1966' },
  { lab: 'Fight Club', val: '1999' },
  { lab: 'The Lord of the Rings: The Fellowship of the Ring', val: '2001' },
  { lab: 'Star Wars: Episode V - The Empire Strikes Back', val: '1980' },
  { lab: 'Forrest Gump', val: '1994' },
  { lab: 'Inception', val: '2010' }];

const Select = () => (
  <>
    <Box mt={3} ml={30}>
      {/* can use either...
        placeholder or menuLabel
        label or inputTitle
        readOnly or disabled
        */}

      <FdSelect
        id="singleOptionDefault"
        label="Single Select with placeholder"
        options={['Test1', 'Test2', 'Test3', 'Test4', 'Test5']}
        helperText="Help Text"
        placeholder="Single Select"
      />
    </Box>
    <Box mt={3} ml={30}>
      <FdSelect
        id="singleOption"
        label="Single Select with default selection"
        options={['Test1', 'Test2', 'Test3', 'Test4', 'Test5']}
        helperText="Help Text"
        defaultSelected="Test1"
        placeholder="Single Select"
      />
    </Box>
    <Box mt={3} ml={30}>
      <FdSelect
        id="multipleOption"
        label="Multiple Select"
        options={[
          'Andrej Crossman',
          'Derek Shepherd',
          'Margot Simons',
          'Ha Lam To',
          'Peter Gabriel',
          'The Lord of the Rings: The Return of the King',
        ]}
        defaultSelected={['Andrej Crossman']}
        helperText="Help Text"
        multiple
      />
    </Box>
    <Box mt={3} ml={30}>
      <FdSelect
        id="singleOptionWithError"
        label="Single Select with Error"
        options={['Test1', 'Test2', 'Test3', 'Test4', 'Test5']}
        defaultSelected="Test1"
        helperText="Help Text"
        error
      />
    </Box>
    <Box mt={3} ml={30}>
      <FdSelect
        id="singleOptionDisabled"
        label="Single Select Disabled"
        options={['Test1', 'Test2', 'Test3', 'Test4', 'Test5']}
        defaultSelected="Test1"
        helperText="Help Text"
        disabled
      />
    </Box>
    <Box mt={3} ml={30}>
      <FdAutocomplete
        id="singleOptionAutocomplete"
        label="SingleSelect autocomplete"
        options={autoCompleteOptions}
        helperText="Help Text"
        placeholder="Select a movie"
        optionLabel="lab"
        optionValue="val"
      />
    </Box>
    <Box mt={3} ml={30}>
      <FdAutocomplete
        id="multiOptionAutocomplete1"
        label="Autocomplete"
        options={autoCompleteOptions}
        helperText="Help Text"
        multiple
        placeholder="Select a movie"
        selectAllLabel="Select all"
        optionLabel="lab"
        optionValue="val"
      />
    </Box>
    <Box mt={3} ml={30}>
      <FdAutocomplete
        id="multiOptionAutocomplete2"
        label="Autocomplete error"
        options={autoCompleteOptions}
        helperText="Help Text"
        multiple
        error
        placeholder="Select a movie"
        selectAllLabel="Select all"
        optionLabel="lab"
        optionValue="val"
      />
    </Box>
    <Box mt={3} ml={30}>
      <FdAutocomplete
        id="multiOptionAutocomplete3"
        label="Autocomplete Disabled"
        options={autoCompleteOptions}
        helperText="Help Text"
        multiple
        disabled
        placeholder="Select a movie"
        selectAllLabel="Select all"
        optionLabel="lab"
        optionValue="val"
      />
    </Box>
  </>
);

export default Select;
