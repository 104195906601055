import React from 'react';
import { Link, Box } from '@material-ui/core';
import { FdBreadcrumbs } from '@fifthdomain/fe-shared';

const Breadcrumbs = () => (
  <Box mt={10} ml={30}>
    <FdBreadcrumbs
      linkChain={[{ url: '/home', name: 'Home' }]}
      currentPage="Create Challenges"
      linkComponent={Link}
    />
  </Box>
);

export default Breadcrumbs;
