import React, { useState } from 'react';
import { FdDateTimePicker, FdIcons } from '@fifthdomain/fe-shared';
import { Box, Typography, makeStyles } from '@material-ui/core';

const { AddAlarm } = FdIcons;

const DateTimePicker = () => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errorDate, setErrorDate] = useState(new Date());

  return (
    <Box ml={10}>
      <Box mb={5}>
        <Typography variant="h3">Default DateTimePicker</Typography>
        <FdDateTimePicker
          id="start date"
          disableToolbar={false}
          label="Start Date"
          value={startDate}
          disablePast
          onChange={setStartDate}
          helperText="Choose your Start Date"
        />
      </Box>
      <Box mb={5}>
        <Typography variant="h3">DateTimePicker with error message</Typography>
        <FdDateTimePicker
          id="error date"
          disableToolbar={false}
          label="Error Date"
          value={errorDate}
          disablePast
          minDateMessage="Start Date cannot be in the past"
          onChange={setErrorDate}
        />
      </Box>
      <Box mb={5}>
        <Typography variant="h3">
          DateTimePicker with customized icon
        </Typography>
        <FdDateTimePicker
          id="end date"
          disableToolbar={false}
          label="End Date"
          value={endDate}
          disablePast
          onChange={setEndDate}
          helperText="Choose your End Date"
          custIcon={<AddAlarm />}
          custIconDirection="end"
        />
      </Box>
      <Box>
        <Typography variant="h3">Disabled DateTimePicker</Typography>
        <FdDateTimePicker
          id="disable date picker"
          disableToolbar={false}
          label="End Date"
          value={endDate}
          disablePast
          onChange={setEndDate}
          helperText="Choose your End Date"
          custIconDirection="end"
          disabled
        />
      </Box>
    </Box>
  );
};

export default DateTimePicker;
