import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { FdStepper } from '@fifthdomain/fe-shared';

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
const errorSteps = ['Create an ad group'];
const completedSteps = ['Select campaign settings'];
const alertMessage = 'Error message';

const Stepper = () => (
  <>
    {/* Stepper horizontal */}
    <Box mt={10} ml={10}>
      <Typography variant="h3">Horizontal stepper</Typography>
      <FdStepper
        steps={steps}
      />
    </Box>

    <Box mt={10} ml={10}>
      <Typography variant="h3">Horizontal stepper showing error, optional and completed state</Typography>
      <FdStepper
        steps={steps}
        errorSteps={errorSteps}
        completedSteps={completedSteps}
        errorMessage={alertMessage}
      />
    </Box>

    <Box mt={10} ml={10}>
      <Typography variant="h3">Label below icon</Typography>
      <FdStepper
        steps={steps}
        errorSteps={errorSteps}
        completedSteps={completedSteps}
        errorMessage={alertMessage}
        alternativeLabel
      />
    </Box>

    {/* Stepper vertical */}
    <Box mt={10} ml={10}>
      <Typography variant="h3">Vertical stepper showing error, optional and completed state</Typography>
      <FdStepper
        vertical
        steps={steps}
        errorSteps={errorSteps}
        completedSteps={completedSteps}
        errorMessage={alertMessage}
      />
    </Box>

  </>
);

export default Stepper;
