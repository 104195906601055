import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { FdAvatar, FdAvatarGroup } from '@fifthdomain/fe-shared';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: '2em',
  },
});

const avatars = [
  { content: 'FD', color: '#5C6BC0' },
  { content: 'YK', color: '#F06292' },
  { content: 'CP', color: '#8BC34A' },
  { content: 'AK', color: '#1976D2' },
  { content: 'TR', color: '#81D4FA' },
];

const Avatar = () => {
  const classes = useStyles();

  return (
    <Box p={5}>
      <Box className={classes.container} mb={5}>
        <FdAvatar variant="circular" content="FD" size="small" />
        <FdAvatar variant="circular" content="FD" />
        <FdAvatar variant="circular" content="YK" size="large" />
      </Box>
      <Box className={classes.container} mb={5}>
        <FdAvatar variant="square" content="FD" size="small" />
        <FdAvatar variant="square" content="FD" />
        <FdAvatar variant="square" content="YK" size="large" />
      </Box>
      <Box mb={5}>
        <FdAvatarGroup
          avatars={avatars}
          size="small"
          variant="circular"
          spacing={10}
          max={4}
        />
      </Box>
      <Box mb={5}>
        <FdAvatarGroup
          avatars={avatars}
          size="medium"
          variant="circular"
          spacing={10}
          max={4}
        />
      </Box>
      <Box mb={5}>
        <FdAvatarGroup
          avatars={avatars}
          size="large"
          variant="circular"
          spacing={10}
          max={4}
        />
      </Box>
      <Box mb={5}>
        <FdAvatarGroup
          avatars={avatars}
          size="large"
          variant="circular"
          spacing={10}
        />
      </Box>
      <Box mb={5}>
        <FdAvatarGroup
          avatars={avatars}
          size="small"
          variant="square"
          spacing={10}
          max={4}
        />
      </Box>
      <Box mb={5}>
        <FdAvatarGroup
          avatars={avatars}
          size="medium"
          variant="square"
          spacing={10}
          max={4}
        />
      </Box>
      <Box mb={5}>
        <FdAvatarGroup
          avatars={avatars}
          size="large"
          variant="square"
          spacing={10}
          max={4}
        />
      </Box>
      <Box mb={5}>
        <FdAvatarGroup
          avatars={avatars}
          size="large"
          variant="square"
          spacing={10}
        />
      </Box>
    </Box>
  );
};

export default Avatar;
