import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { FdMultiChipTextField } from '@fifthdomain/fe-shared';

const MultiChipTextField = () => {
  const [values, setValues] = useState([]);
  return (
    <Box mt={10} ml={30}>
      <Box display="flex" alignItems="flex-end" mb={2} mt={0.5}>
        <FdMultiChipTextField
          width="80%"
          id="text-field"
          label="Multi Chip text field"
          placeholder="Emails"
          helperText="copy paste emails"
          required
          values={values}
          setValues={setValues}
        />
      </Box>
    </Box>
  );
};

export default MultiChipTextField;
