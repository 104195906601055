import React from 'react';
import { Box } from '@material-ui/core';
import { FdRadioGroup, FdRadioScale } from '@fifthdomain/fe-shared';

const Radio = () => (
  <Box ml={5} width="70%">
    <Box mb={5}>
      <FdRadioGroup
        id="competitions"
        label="Competitions"
        options={[
          'Live Competition',
          'Test Competition',
          'Fifth Domain Competition',
        ]}
        onChange={() => { }}
        disabled={[2]}
      />
    </Box>
    <Box mb={5}>
      <FdRadioGroup
        id="competitions"
        label="Competitions"
        options={[
          'Live Competition',
          'Test Competition',
        ]}
        onChange={() => { }}
        defaultValue="Live Competition"
        disabled={[0, 1]}
      />
    </Box>
    <Box mb={5}>
      <FdRadioGroup
        id="competitions"
        label="Competitions"
        options={['Live Competition', 'Test Competition']}
        onChange={() => { }}
        error="Please select an option"
      />
    </Box>
    <Box mb={5}>
      <FdRadioScale
        id="radio-scale"
        label="I share ideas with others to accomplish a task"
        onChange={() => { }}
        scale={5}
        startText="Never True"
        endText="Always True"
      />
    </Box>
    <Box mb={5}>
      <FdRadioScale
        id="radio-scale"
        label="I share ideas with others to accomplish a task"
        onChange={() => { }}
        scale={5}
        startText="Never True"
        endText="Always True"
        error="Please select an option"
      />
    </Box>
  </Box>
);

export default Radio;
