import React from 'react';
import { Box } from '@material-ui/core';
import { FdChip, FdIcons } from '@fifthdomain/fe-shared';

const { AccountCircle } = FdIcons;

const icon = () => <AccountCircle />;
const handleDelete = () => {};
const handleClick = () => {};

const Chip = () => (
  <>
    <Box
      mt={10}
      ml={30}
      display="flex"
      width="20%"
      justifyContent="space-around"
    >
      <FdChip color="success" size="small" label="Small" />
      <FdChip color="success" size="medium" label="Medium" />
    </Box>
    <Box
      mt={3}
      ml={30}
      display="flex"
      width="20%"
      justifyContent="space-around"
    >
      <FdChip color="warning" size="small" label="Small" />
      <FdChip color="warning" size="medium" label="Medium" />
    </Box>
    <Box
      mt={3}
      ml={30}
      display="flex"
      width="20%"
      justifyContent="space-around"
    >
      <FdChip color="error" size="small" label="Small" />
      <FdChip color="error" size="medium" label="Medium" />
    </Box>
    <Box
      mt={3}
      ml={30}
      display="flex"
      width="20%"
      justifyContent="space-around"
    >
      <FdChip color="action" size="small" label="Small" />
      <FdChip color="action" size="medium" label="Medium" />
    </Box>
    <Box
      mt={3}
      ml={30}
      display="flex"
      width="20%"
      justifyContent="space-around"
    >
      <FdChip color="default" size="small" label="Small" />
      <FdChip color="default" size="medium" label="Medium" />
    </Box>
    {/* Chip with icon */}
    <Box
      mt={3}
      ml={30}
      display="flex"
      width="20%"
      justifyContent="space-around"
    >
      <FdChip icon={icon} size="small" label="Small Icon" />
      <FdChip icon={icon} size="medium" label="Medium with Icon" />
    </Box>
    {/* Chip that can be deleted */}
    <Box
      mt={3}
      ml={30}
      display="flex"
      width="20%"
      justifyContent="space-around"
    >
      <FdChip
        onDelete={handleDelete}
        color="default"
        size="small"
        label="Deletable"
      />
      <FdChip
        onDelete={handleDelete}
        color="default"
        size="medium"
        label="Deletable"
      />
    </Box>
    {/* Selectable chip */}
    <Box
      mt={3}
      ml={30}
      display="flex"
      width="20%"
      justifyContent="space-around"
    >
      <FdChip
        onClick={handleClick}
        color="default"
        size="small"
        label="Selectable"
      />
      <FdChip
        onClick={handleClick}
        color="default"
        size="medium"
        label="Selectable"
      />
    </Box>
    <Box
      mt={3}
      ml={30}
      display="flex"
      width="20%"
      justifyContent="space-around"
    >
      <FdChip
        onClick={handleClick}
        color="default"
        size="small"
        label="LabelType"
        labelType
      />
      <FdChip
        onClick={handleClick}
        color="default"
        size="medium"
        label="LabelType"
        labelType
      />
    </Box>
  </>
);

export default Chip;
