import React from 'react';
import { FdTooltip, FdIcons } from '@fifthdomain/fe-shared';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';

const { Info, Delete, IconButton } = FdIcons;

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '1em',
  },
});

const Tooltip = () => {
  const classes = useStyles();

  return (
    <Box ml={10} className={classes.container}>
      <FdTooltip title="default tooltip" position="bottom-start">
        <Info />
      </FdTooltip>
      <FdTooltip title="Delete" arrow>
        <IconButton aria-label="delete">
          <Delete />
        </IconButton>
      </FdTooltip>
      <FdTooltip title="so so so so so so so very very very very very very very long long long long o so so so so so so very very very very very very very long long long long texttext">
        <Button variant="contained" color="primary">
          tool tip with long text
        </Button>
      </FdTooltip>
      <FdTooltip
        title={<Typography color="inherit">Tooltip with HTML</Typography>}
      >
        <Button>Tooltip with component</Button>
      </FdTooltip>
    </Box>
  );
};

export default Tooltip;
