import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { FdProgress } from '@fifthdomain/fe-shared';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
}));
const Progress = () => {
  const classes = useStyles();

  return (
    <Box m={10} width="70%" className={classes.root}>
      <FdProgress type="circular" size="small" />
      <FdProgress type="circular" size="medium" />
      <FdProgress type="circular" size="large" />
      <FdProgress type="linear" value={100} />
      <FdProgress type="linear" value={75} />
      <FdProgress type="linear" value={50} />
      <FdProgress type="linear" value={20} />
    </Box>
  );
};

export default Progress;
