import React from 'react';
import { Box } from '@material-ui/core';

import { FdToggleButtons, FdIcons } from '@fifthdomain/fe-shared';

const { Dehaze, ViewModule, PieChart } = FdIcons;

const twoOptions = [
  { key: 'val1', content: <Dehaze />, disabled: false },
  { key: 'val2', content: <ViewModule />, disabled: false },
];
const threeOptions = [
  { key: 'val1', content: <Dehaze />, disabled: false },
  { key: 'val2', content: <ViewModule />, disabled: false },
  { key: 'val3', content: <PieChart />, disabled: false },
];
const options = [
  { key: 'yes', content: 'Yes', disabled: false },
  { key: 'no', content: 'No', disabled: false },
  { key: 'notsure', content: 'Not Sure', disabled: false },
];
const defaultOptions = ['val2'];
const threeDisabledOptions = [
  { key: 'val1', content: <Dehaze />, disabled: true },
  { key: 'val2', content: <ViewModule />, disabled: false },
  { key: 'val3', content: <PieChart />, disabled: false },
];

const ToggleButton = () => (
  <>
    <Box mt={5} ml={10}>
      <FdToggleButtons
        id="two-button-toggle"
        options={twoOptions}
        onChange={() => {}}
      />
    </Box>
    <Box mt={5} ml={10}>
      <FdToggleButtons
        id="three-button-toggle"
        options={threeOptions}
        onChange={() => {}}
      />
    </Box>
    <Box mt={5} ml={10}>
      <FdToggleButtons
        id="question-title"
        options={threeOptions}
        onChange={() => {}}
        label="Did you learn something at this event that you would not have had access to otherwise?"
      />
    </Box>
    <Box mt={5} ml={10}>
      <FdToggleButtons
        id="has-error"
        options={threeOptions}
        onChange={() => {}}
        exclusive
        error
        errorText="Please select an option"
      />
    </Box>
    <Box mt={5} ml={10}>
      <FdToggleButtons
        id="with-default"
        options={threeOptions}
        onChange={() => {}}
        exclusive
        defaultValues={defaultOptions}
      />
    </Box>
    <Box mt={5} ml={10}>
      <FdToggleButtons
        id="flexWidth"
        options={options}
        onChange={() => {}}
        flexWidth
      />
    </Box>
    <Box mt={5} ml={10}>
      <FdToggleButtons
        id="three-button-toggle-disabled"
        options={threeDisabledOptions}
        onChange={() => {}}
      />
    </Box>
  </>
);

export default ToggleButton;
