import React, { useEffect, useState } from 'react';
import {
  ThemeProvider,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormLabel,
  Select,
  Paper,
  RadioGroup,
  Radio as RadioButton,
  makeStyles,
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core';
import { setShowNav, setTheme, useAppTheme } from '@fifthdomain/fe-shared';
import {
  Button,
  Tabs,
  Alert,
  Table,
  Chip,
  Breadcrumbs,
  Select as FSelect,
  Checkbox,
  DropdownMenu,
  MarkdownEditor,
  TextField,
  Modal,
  Switch,
  Accordion,
  Radio,
  ToggleButton,
  Progress,
  Toast,
  Badge,
  Avatar,
  DateTimePicker,
  Tooltip,
  CardMini,
  Card,
  MultiChipTextField,
  Stepper,
  Typography,
} from './components';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    minHeight: '35vh',
    paddingTop: '10vh',
    paddingBottom: '30vh',
  },
}));
const generateClassName = createGenerateClassName({
  productionPrefix: 'component-library-view',
  seed: 'component-library-view',
});

const options = [
  'Button',
  'Tabs',
  'Alert',
  'Table',
  'Chip',
  'Breadcrumbs',
  'Select',
  'Checkbox',
  'DropdownMenu',
  'MarkdownEditor',
  'TextField',
  'Modal',
  'Switch',
  'Accordion',
  'Radio',
  'ToggleButton',
  'Progress',
  'Toast',
  'Badge',
  'Avatar',
  'DateTimePicker',
  'Tooltip',
  'CardMini',
  'Card',
  'MultiChipTextField',
  'Stepper',
  'Typography',
];

const App = () => {
  const theme = useAppTheme();
  const classes = useStyles();
  const [component, setComponent] = useState('Accordion');

  useEffect(() => {
    setShowNav(true);
  });

  const handleChange = (event) => {
    setComponent(event.target.value);
  };

  const toggleTheme = (e) => {
    setTheme(e.target.value);
  };

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <Box
          pt={10}
          pl={40}
          pr={10}
          style={{
            background: theme.palette.background.default,
            color: theme.palette.typography.primary,
          }}
        >
          <Box>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                style={{
                  color: theme.palette.typography.primary,
                  fontWeight: '800',
                }}
              >
                Theme
              </FormLabel>
              <RadioGroup
                aria-label="theme"
                name="theme"
                value={theme.palette.mode}
                onChange={toggleTheme}
              >
                <FormControlLabel
                  value="light"
                  control={<RadioButton />}
                  label="light"
                />
                <FormControlLabel
                  value="dark"
                  control={<RadioButton />}
                  label="dark"
                />
                <FormControlLabel
                  value="green"
                  control={<RadioButton />}
                  label="green"
                />
                <FormControlLabel
                  value="retro"
                  control={<RadioButton />}
                  label="retro"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box>
            <FormControl className={classes.formControl}>
              <InputLabel
                id="demo-simple-select-label"
                style={{
                  color: theme.palette.typography.primary,
                  fontSize: '20px',
                  fontWeight: '800',
                }}
              >
                Component
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={component}
                onChange={handleChange}
              >
                {options.sort().map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Paper className={classes.paper}>
              {component === 'Button' && <Button />}
              {component === 'Tabs' && <Tabs />}
              {component === 'Alert' && <Alert />}
              {component === 'Chip' && <Chip />}
              {component === 'Modal' && <Modal />}
              {component === 'Breadcrumbs' && <Breadcrumbs />}
              {component === 'Table' && <Table />}
              {component === 'Select' && <FSelect />}
              {component === 'Checkbox' && <Checkbox />}
              {component === 'DropdownMenu' && <DropdownMenu />}
              {component === 'MarkdownEditor' && <MarkdownEditor />}
              {component === 'TextField' && <TextField />}
              {component === 'Switch' && <Switch />}
              {component === 'Accordion' && <Accordion />}
              {component === 'Radio' && <Radio />}
              {component === 'ToggleButton' && <ToggleButton />}
              {component === 'Progress' && <Progress />}
              {component === 'Toast' && <Toast />}
              {component === 'Badge' && <Badge />}
              {component === 'Avatar' && <Avatar />}
              {component === 'DateTimePicker' && <DateTimePicker />}
              {component === 'Tooltip' && <Tooltip />}
              {component === 'CardMini' && <CardMini />}
              {component === 'Card' && <Card />}
              {component === 'Stepper' && <Stepper />}
              {component === 'Typography' && <Typography />}
              {component === 'MultiChipTextField' && <MultiChipTextField />}
            </Paper>
          </Box>
        </Box>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
