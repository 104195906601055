import React from 'react';
import { Box } from '@material-ui/core';
import { FdTextField, FdIcons } from '@fifthdomain/fe-shared';

const { AccountCircle } = FdIcons;

const TextField = () => (
  <Box mt={10} ml={30}>
    <Box display="flex" alignItems="flex-end" mb={2} mt={0.5}>
      <FdTextField
        width="50%"
        id="text-field"
        helperText="Help Text"
        label="Input Title"
        placeholder="Placeholder"
      />
    </Box>
    <Box display="flex" alignItems="flex-end" mb={2} mt={0.5}>
      <FdTextField
        width="50%"
        id="text-field"
        helperText="Help Text"
        label="Required Title"
        placeholder="Placeholder"
        required
      />
    </Box>
    <Box display="flex" alignItems="flex-end" mb={2} mt={0.5}>
      <FdTextField
        width="50%"
        id="text-field"
        helperText="Help Text"
        label="very very very veryveryveryveryveryvery very very very very very very Looooooooooooooooooooooooooooooooooooooooooooooooong title"
        placeholder="Some long placeholder text that is so so so so so so so very  veryveryveryveryveryveryveryvery very long that it will go onto multiple lines"
        required
        multiline
      />
    </Box>
    <Box display="flex" alignItems="flex-end" mb={2} mt={0.5}>
      <FdTextField
        error
        width="50%"
        id="standard-error-helper-text"
        helperText="incorrect entry"
        label="error title"
        placeholder="Placeholder"
      />
    </Box>
    <Box display="flex" alignItems="flex-end" mb={2} mt={0.5}>
      <FdTextField
        width="50%"
        id="standard-disabled-helper-text"
        helperText="multiline"
        label="multiline disabled title"
        placeholder="Placeholder"
        multiline
        disabled
      />
    </Box>
    <Box display="flex" alignItems="flex-end" mb={2} mt={0.5}>
      <FdTextField
        width="50%"
        id="text-field"
        label="Text field with prefix"
        placeholder="Enter your amount in Australian Dollars"
        required
        startAdornment="$"
      />
    </Box>
    <Box display="flex" alignItems="flex-end" mb={2} mt={0.5}>
      <FdTextField
        width="50%"
        id="text-field"
        label="Text field with suffix"
        placeholder="Enter your duration"
        required
        endAdornment="Minutes"
      />
    </Box>
    <Box display="flex" alignItems="flex-end" mb={2} mt={0.5}>
      <FdTextField
        width="50%"
        id="text-field"
        label="Text field with icon"
        placeholder="Username"
        required
        startAdornment={<AccountCircle color="inherit" />}
      />
    </Box>
  </Box>
);

export default TextField;
