import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { FdBadge } from '@fifthdomain/fe-shared';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: '1em',
  },
});

const Badge = () => {
  const classes = useStyles();
  return (
    <Box p={5} display="flex" flexDirection="column">
      <Box mb={5} className={classes.container}>
        <FdBadge content="YK" variant="dot" position="bottom-left" />
        <FdBadge content="FD" variant="dot" position="top-right" />
      </Box>
      <Box className={classes.container}>
        <FdBadge
          content={{
            alt: 'Random',
            src: 'https://source.unsplash.com/random/200x300',
          }}
          variant="dot"
        />
        <FdBadge
          content={{
            alt: 'Random',
            src: 'https://source.unsplash.com/random/200x300',
          }}
          position="bottom-right"
          variant="standard"
        />
      </Box>
    </Box>
  );
};

export default Badge;
