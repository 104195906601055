import React, { useState } from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import { FdDropdownMenu, FdIcons } from '@fifthdomain/fe-shared';

const { MoveToInbox, Drafts, Send } = FdIcons;

const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: '1em',
  },
});

const DropdownMenu = () => {
  const classes = useStyles();
  const [defaultMenu, setDefaultMenu] = useState(null);
  const [largeMenu, setLargeMenu] = useState(null);
  const [mediumMenu, setMediumMenu] = useState(null);
  const [customIconMenu, setCustomIconMenu] = useState(null);
  const [dividerMenu, setDividerMenu] = useState(null);

  return (
    <Box mt={10} ml={10} className={classes.container}>
      <Box>
        <Button
          onClick={(event) => setDefaultMenu(event.currentTarget)}
          variant="contained"
          color="primary"
          aria-controls="app-menu"
          aria-haspopup="true"
        >
          Default Menu
        </Button>
        <FdDropdownMenu
          id="app-menu"
          menuLabel="default Menu"
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          menuOptions={[
            { id: 'home', name: 'Home' },
            { id: 'ctf', name: 'Competitions' },
            { id: 'range', name: 'Range' },
            { id: 'labs', name: 'Learning Labs' },
          ]}
          anchor={defaultMenu}
          setAnchor={setDefaultMenu}
          selectedMenuItems={[]}
          menuCallback={() => {}}
          variant="medium"
          closeOnSelect
        />
      </Box>
      <Box>
        <Button
          onClick={(event) => setMediumMenu(event.currentTarget)}
          variant="contained"
          color="primary"
          aria-controls="medium-menu"
          aria-haspopup="true"
        >
          Medium Menu
        </Button>
        <FdDropdownMenu
          id="medium-menu"
          menuLabel="Medium Menu"
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          menuOptions={[
            { id: 'home', name: 'Home' },
            { id: 'ctf', name: 'Competitions' },
            { id: 'range', name: 'Range' },
            { id: 'labs', name: 'Learning Labs' },
          ]}
          anchor={mediumMenu}
          setAnchor={setMediumMenu}
          selectedMenuItems={[]}
          menuCallback={() => {}}
          variant="medium"
          closeOnSelect
        />
      </Box>
      <Box>
        <Button
          onClick={(event) => setLargeMenu(event.currentTarget)}
          variant="contained"
          color="primary"
          aria-controls="large-menu"
          aria-haspopup="true"
        >
          Large Menu
        </Button>
        <FdDropdownMenu
          id="large-menu"
          menuLabel="default Menu"
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          menuOptions={[
            { id: 'home', name: 'Home' },
            { id: 'ctf', name: 'Competitions' },
            { id: 'range', name: 'Range' },
            { id: 'labs', name: 'Learning Labs' },
          ]}
          anchor={largeMenu}
          setAnchor={setLargeMenu}
          selectedMenuItems={['home']}
          menuCallback={() => {}}
          variant="large"
          closeOnSelect
        />
      </Box>
      <Box>
        <Button
          onClick={(event) => setCustomIconMenu(event.currentTarget)}
          variant="contained"
          color="primary"
          aria-controls="icon-menu"
          aria-haspopup="true"
        >
          Menu with icon
        </Button>
        <FdDropdownMenu
          id="icon-menu"
          menuLabel="Icon Menu"
          ttransformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          menuOptions={[
            { id: 'inbox', name: 'Inbox', icon: () => <MoveToInbox /> },
            { id: 'draft', name: 'Draft', icon: () => <Drafts /> },
            { id: 'send', name: 'Send', icon: () => <Send /> },
          ]}
          anchor={customIconMenu}
          setAnchor={setCustomIconMenu}
          selectedMenuItems={[]}
          menuCallback={() => {}}
          variant="large"
          closeOnSelect
        />
      </Box>
      <Box>
        <Button
          onClick={(event) => setDividerMenu(event.currentTarget)}
          variant="contained"
          color="primary"
          aria-controls="divider-menu"
          aria-haspopup="true"
        >
          Divider Menu
        </Button>
        <FdDropdownMenu
          id="divider-menu"
          menuLabel="Divider Menu"
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          menuOptions={[
            { id: 'home', name: 'Home' },
            { id: 'ctf', name: 'Competitions' },
            { id: 'range', name: 'Range' },
            { id: 'labs', name: 'Learning Labs' },
          ]}
          anchor={dividerMenu}
          setAnchor={setDividerMenu}
          selectedMenuItems={['home']}
          menuCallback={() => {}}
          variant="large"
          closeOnSelect
          divider
        />
      </Box>
    </Box>
  );
};

export default DropdownMenu;
