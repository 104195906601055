import React from 'react';
import { Box, Link } from '@material-ui/core';
import { FdCardMini, FdTypography, FdIcons } from '@fifthdomain/fe-shared';

const { Fingerprint } = FdIcons;

const FingerPrintIcon = () => <Fingerprint />;
const description =
  'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis en...';
const difficultyPoints = '600 pts';
const subTitle = 'Category Title';
const iconContent = <FingerPrintIcon />;
const cardAction = () => {};

const avatars = [
  { content: 'FD', color: '#5C6BC0' },
  { content: 'YK', color: '#F06292' },
  { content: 'CP', color: '#8BC34A' },
  { content: 'AK', color: '#1976D2' },
  { content: 'TR', color: '#81D4FA' },
];

const dependencyLink = (
  <FdTypography display="inline" variant="captiontext1">
    Dependency:
    <Link href="/" underline="always">
      Book of Ciphers 8 with a long lon...
    </Link>
  </FdTypography>
);

const CardMini = () => (
  <>
    <Box mx={1}>
      <FdCardMini
        type="challenge"
        iconContent={iconContent}
        title="Challenge and default"
        subTitle={subTitle}
        difficultyLabel={difficultyPoints}
        description={description}
        avatars={avatars}
        status="default"
        cardAction={cardAction}
      />
    </Box>

    <Box mx={1}>
      <FdCardMini
        type="challenge"
        iconContent={iconContent}
        title="Challenge and completed"
        subTitle={subTitle}
        difficultyLabel={difficultyPoints}
        description={description}
        status="completed"
        cardAction={cardAction}
      />
    </Box>

    <Box mx={1}>
      <FdCardMini
        type="challenge"
        iconContent={iconContent}
        title="Challenge and locked"
        subTitle={subTitle}
        difficultyLabel={difficultyPoints}
        description={description}
        status="locked"
        cardAction={cardAction}
        dependencyLink={dependencyLink}
      />
    </Box>

    <Box mx={1}>
      <FdCardMini
        type="challenge"
        iconContent={iconContent}
        title="Challenge and disabled"
        subTitle={subTitle}
        difficultyLabel={difficultyPoints}
        description={description}
        status="disabled"
      />
    </Box>

    <Box mx={1}>
      <FdCardMini
        type="challenge"
        iconContent={iconContent}
        title="Challenge and wide"
        subTitle={subTitle}
        difficultyLabel={difficultyPoints}
        description={description}
        status="default"
        avatars={avatars}
        mediumWidth
        cardAction={cardAction}
        actionButtonLabel="Open"
      />
    </Box>
  </>
);

export default CardMini;
