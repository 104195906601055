import React from 'react';
import { Box } from '@material-ui/core';
import { FdSwitch } from '@fifthdomain/fe-shared';

const Switch = () => (
  <>
    <Box mt={10} ml={30}>
      <FdSwitch status='ON' />
    </Box>

    <Box mt={10} ml={30}>
      <FdSwitch disabled status='ON' />
    </Box>

    <Box mt={10} ml={30}>
      <FdSwitch disabled status='OFF' />
    </Box>
  </>
);

export default Switch;
