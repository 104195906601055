import React from 'react';
import { Box } from '@material-ui/core';
import { FdButton, FdIcons } from '@fifthdomain/fe-shared';

const { Send, Save } = FdIcons;

const Button = () => (
  <>
    <Box
      mt={10}
      ml={30}
      display="flex"
      width="50%"
      justifyContent="space-around"
    >
      <FdButton size="small" variant="tertiary">
        Small
      </FdButton>
      <FdButton size="small" variant="secondary">
        Small
      </FdButton>
      <FdButton size="small" variant="primary">
        Small
      </FdButton>
    </Box>
    <Box
      mt={3}
      ml={30}
      display="flex"
      width="50%"
      justifyContent="space-around"
    >
      <FdButton size="medium" variant="tertiary">
        Medium
      </FdButton>
      <FdButton size="medium" variant="secondary">
        Medium
      </FdButton>
      <FdButton size="medium" variant="primary">
        Medium
      </FdButton>
    </Box>
    <Box
      mt={3}
      ml={30}
      display="flex"
      width="50%"
      justifyContent="space-around"
    >
      <FdButton size="large" variant="tertiary">
        Large
      </FdButton>
      <FdButton size="large" variant="secondary">
        Large
      </FdButton>
      <FdButton size="large" variant="primary">
        Large
      </FdButton>
      <FdButton
        size="large"
        variant="primary"
        style={{
          backgroundColor: '#C62828',
          color: '#FFFFFF',
          borderColor: '#C62828',
        }}
      >
        Error
      </FdButton>
    </Box>
    <Box
      mt={3}
      ml={30}
      mb={10}
      display="flex"
      width="50%"
      justifyContent="space-around"
    >
      <FdButton size="large" variant="tertiary" disabled>
        Disabled
      </FdButton>
      <FdButton size="large" variant="secondary" disabled>
        Disabled
      </FdButton>
      <FdButton size="large" variant="primary" disabled>
        Disabled
      </FdButton>
    </Box>
    <Box ml={30} display="flex" width="50%" justifyContent="space-around">
      <FdButton size="large" variant="primary" endIcon={<Send />}>
        Send
      </FdButton>
      <FdButton size="large" variant="primary" startIcon={<Save />}>
        Save
      </FdButton>
    </Box>
  </>
);

export default Button;
