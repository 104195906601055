import React from 'react';
import { Box } from '@material-ui/core';
import { FdTable, FdTypography } from '@fifthdomain/fe-shared';
import { columns, rows } from './data';

const Table = () => {
  const actions = [
    {
      label: 'Remove',
      show: () => true,
    },
  ];

  return (
    <>
      <Box height="500px" m={5}>
        <FdTable
          toolbarSettings={{
            title: 'Challenges',
            headerActions: [
              {
                label: 'Manage Challenges',
                onClick: () => { },
              },
            ],
            filterButton: true,
            searchBox: true,
          }}
          selection
          actions={actions}
          rows={rows}
          columns={columns}
          noRowsMessage="No Challenges to display. Manage Challenges to Add and Remove Challenges."
          pagesize={5}
        />
      </Box>

      <Box height="500px" m={5}>
        <Box mb={1}>
          <FdTypography variant="subtitle1">Table with checkboxSelectionVisibleOnly(false) and bulkAction </FdTypography>
        </Box>

        <FdTable
          toolbarSettings={{
            title: 'Challenges',
            headerActions: [
              {
                label: 'Manage Challenges',
                onClick: () => { },
              },
            ],
            filterButton: true,
            searchBox: true,
            bulkActions: true,
          }}
          selection
          checkboxSelectionVisibleOnly={false}
          actions={actions}
          rows={rows}
          columns={columns}
          noRowsMessage="No Challenges to display. Manage Challenges to Add and Remove Challenges."
          pagesize={5}
        />
      </Box>
    </>
  );
};

export default Table;
