import { useEffect } from 'react';
import { useToast, withSnackbarProvider } from '@fifthdomain/fe-shared';

const Toast = () => {
  const { showToast } = useToast();

  useEffect(() => {
    showToast({
      message: 'success message',
      autoHideDuration: 8000,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      variant: 'success',
      persist: true,
      showCloseIcon: false,
    });
    showToast({
      message: 'default message',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      variant: 'default',
      persist: true,
      showCloseIcon: true,
    });
    showToast({
      message: 'error message',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      variant: 'error',
      showCloseIcon: true,
    });
    showToast({
      message: 'info message',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      variant: 'info',
      showCloseIcon: true,
      autoHideDuration: 5000,
    });
  }, [showToast]);

  return null;
};

export default withSnackbarProvider(Toast);
