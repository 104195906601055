import React from 'react';
import { Box } from '@material-ui/core';
import {
  FdAccordion,
  FdTypography as Typography,
} from '@fifthdomain/fe-shared';

const Accordion = () => (
  <Box width="70%" ml={5}>
    <FdAccordion
      summary={() => <Typography variant="h3">Accordion 1</Typography>}
      open
      content={() => <Typography variant="body1">Accordion one Details</Typography>}
    />
    <FdAccordion
      summary={() => <Typography variant="h3">Accordion 2</Typography>}
      content={() => <Typography variant="body1">Accordion two details</Typography>}
    />
  </Box>
);

export default Accordion;
