import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import {
  FdModal,
  FdTypography,
  FdSelect,
  FdChip,
  FdTextField,
  FdButton,
} from '@fifthdomain/fe-shared';

const useStyles = makeStyles((theme) => ({
  chip: {
    color: theme.palette.typography.primary,
    '&:hover': {
      backgroundColor: '#EFEFEF !important',
    },
  },
  selectedChip: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.common.white} !important`,
  },
  flexBox: {
    display: 'flex',
    gap: '2em',
    justifyContent: 'flex-start',
  },
}));

const Modal = () => {
  const classes = useStyles();
  const [modal, setModalOpen] = useState(false);
  const [feedbackModal, setFeedbackModalOpen] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [modalEightHundred, setModalEightHundred] = useState(false);
  const [modalSixHundred, setModalSixHundred] = useState(false);
  const [modalFiveHundred, setModalFiveHundred] = useState(false);
  const [modalFourHundred, setModalFourHundred] = useState(false);
  const [selected, setSelected] = useState([]);

  return (
    <>
      <Box mt={10} ml={30} width="70%">
        <Box className={classes.flexBox} mb={5}>
          <FdButton size="medium" onClick={() => setModalOpen(true)}>
            Normal Modal
          </FdButton>
          <FdButton size="medium" onClick={() => setFeedbackModalOpen(true)}>
            Feedbak Modal
          </FdButton>
          <FdButton size="medium" onClick={() => setActionModal(true)}>
            Modal with action
          </FdButton>
        </Box>
        <Box className={classes.flexBox}>
          <FdButton
            variant="secondary"
            size="medium"
            onClick={() => setModalEightHundred(true)}
          >
            Modal with 800px
          </FdButton>
          <FdButton
            variant="secondary"
            size="medium"
            onClick={() => setModalSixHundred(true)}
          >
            Modal with 600px
          </FdButton>
          <FdButton
            variant="secondary"
            size="medium"
            onClick={() => setModalFourHundred(true)}
          >
            Modal with 400px
          </FdButton>
          <FdButton
            variant="secondary"
            size="medium"
            onClick={() => setModalFiveHundred(true)}
          >
            Modal with 500px
          </FdButton>
        </Box>
        {/* Normal Modal */}
        <FdModal
          size="md"
          title="Archive Competition Template"
          description="When you archive a competition template it shows up in the Archived tab and you can restore."
          confirm="ARCHIVE"
          dismiss="CANCEL"
          open={modal}
          onConfirm={() => {
            setModalOpen(false);
          }}
          onClose={(_, reason) => {
            // new onBackdropClick
            if (reason === 'backdropClick') {
              setModalOpen(true);
            }
            // new onEscapeKeyDown
            if (reason === 'escapeKeyDown') {
              setModalOpen(false);
            }
          }}
          onDismiss={() => {
            setModalOpen(false);
          }}
        />
        {/* Feddback Modal */}
        <FdModal
          size="md"
          title="Feedback"
          confirm="SUBMIT"
          dismiss="CANCEL"
          open={feedbackModal}
          onConfirm={() => {
            setFeedbackModalOpen(false);
          }}
          onDismiss={() => {
            setFeedbackModalOpen(false);
          }}
          onClose={(_, reason) => {
            // new onBackdropClick
            if (reason === 'backdropClick') {
              setFeedbackModalOpen(true);
            }
            // new onEscapeKeyDown
            if (reason === 'escapeKeyDown') {
              setFeedbackModalOpen(false);
            }
          }}
        >
          <Box display="flex">
            <Box width="50%" mr={2}>
              <FdSelect
                inputTitle="Feedback Type"
                id="Feedback Type"
                options={['Test1', 'Test2', 'Test3', 'Test4', 'Test5']}
                fullWidth
              />
            </Box>
            <Box width="50%">
              <FdSelect
                inputTitle="Module"
                id="Module"
                options={['Test1', 'Test2', 'Test3', 'Test4', 'Test5']}
                fullWidth
              />
            </Box>
          </Box>
          <Box mt={3}>
            <FdTypography variant="subtitle1">
              Topics (Select one or more)
            </FdTypography>
            <Box display="flex" justifyContent="space-around">
              {[
                'Relevance',
                'Enjoyment',
                'Difficulty',
                'Engagement',
                'Issue',
                'other',
              ].map((c, index) => (
                <FdChip
                  key={index}
                  size="medium"
                  label={c}
                  className={
                    selected.length > 0 && selected.some((chip) => chip === c)
                      ? classes.selectedChip
                      : classes.chip
                  }
                  onClick={() => {
                    if (selected.indexOf(c) !== -1) {
                      setSelected(selected.filter((i) => i !== c));
                      return;
                    }
                    setSelected([...new Set([...selected, c])]);
                  }}
                />
              ))}
            </Box>
          </Box>
          <Box mt={3}>
            <FdTypography variant="subtitle1">Discuss</FdTypography>
            <FdTextField
              width="100%"
              id="feedback"
              placeholder="I really enjoyed this module but it is a bit hard. "
              multiline
            />
          </Box>
        </FdModal>

        {/* Modal with actions */}
        <FdModal
          size="md"
          title="Save Challenges"
          description="Save your challenges or go back or cancel"
          confirm="SAVE"
          dismiss="CANCEL"
          actions={[
            {
              text: 'BACK',
              callback: () => {
                setActionModal(false);
              },
            },
          ]}
          open={actionModal}
          onConfirm={() => {
            setActionModal(false);
          }}
          onDismiss={() => {
            setActionModal(false);
          }}
        />

        {/* Modal with different sizes */}
        <FdModal
          size="lg"
          title="Modal with width 800"
          description="This is nothing but the Modal with width 800"
          confirm="SUBMIT"
          dismiss="CANCEL"
          open={modalEightHundred}
          onConfirm={() => {
            setModalEightHundred(false);
          }}
          onDismiss={() => {
            setModalEightHundred(false);
          }}
        />
        <FdModal
          size="md"
          title="Modal with width 600"
          description="This is nothing but the Modal with width 600"
          confirm="SUBMIT"
          dismiss="CANCEL"
          open={modalSixHundred}
          onConfirm={() => {
            setModalSixHundred(false);
          }}
          onDismiss={() => {
            setModalSixHundred(false);
          }}
        />
        <FdModal
          size="sm"
          title="Modal with width 500"
          description="This is nothing but the Modal with width 500"
          confirm="SUBMIT"
          dismiss="CANCEL"
          open={modalFiveHundred}
          onConfirm={() => {
            setModalFiveHundred(false);
          }}
          onDismiss={() => {
            setModalFiveHundred(false);
          }}
        />
        <FdModal
          size="xs"
          title="Modal with width 400"
          description="This is nothing but the Modal with width 400"
          confirm="SUBMIT"
          dismiss="CANCEL"
          open={modalFourHundred}
          onConfirm={() => {
            setModalFourHundred(false);
          }}
          onDismiss={() => {
            setModalFourHundred(false);
          }}
        />
      </Box>
    </>
  );
};

export default Modal;
