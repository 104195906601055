import React from 'react';
import { Box } from '@material-ui/core';
import { FdCard } from '@fifthdomain/fe-shared';

const Card = () => (
  <Box mx={1}>
    <FdCard heading="Card Title" subHeading="Subheading">
      Card Content
    </FdCard>
    <FdCard heading="Card Title" subHeading="Subheading" optional>
      Card Content
    </FdCard>
    <FdCard
      heading="Card Title"
      subHeading="Subheading"
      preHeader="Pre header content (node)"
    >
      Card Content
    </FdCard>
  </Box>
);

export default Card;
